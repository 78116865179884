import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DateTimeField from '@/shared/fields/date-time-field';

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  accountingDepartmentMailbox: new StringField('accountingDepartmentMailbox', label('accountingDepartmentMailbox'), {
    required: false,
    matches: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }),
  legalDepartmentMailbox: new StringField('legalDepartmentMailbox', label('legalDepartmentMailbox'), {
    required: false,
    matches: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
};

export class SettingsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
